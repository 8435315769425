.container {
    flex: 1;
    flex-direction: 'column'
}

.cardArea {
    align-self: flex-end;
    background: #fff 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    display: flex;
    height: calc(100vh - 200px);
    margin-left: 20px;
    opacity: 1;
    overflow: auto;
    position: absolute;
    top: 150px;
    width: calc(100% - 50px);
    flex-direction: column;
}

.card {
    background-color: #f8f8f8;
    height: fit-content;
    width: -webkit-fill-available;
    border-bottom-right-radius: 5px;
}

.card2 {
    margin-top: 10px;
    max-width: 400px;
    min-width: 300px;
    border-radius: 5px;
    box-shadow: 0 3px 6px #00000029;
    background-color: #0d324d;
    display: flex;
}

.title {
    color: #0520347A;
    margin-top: 5;
    font-size: 11px
}

.titleText {
    color: #052034D6;

}

.cascadingfamily1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
}

.cascadingfamily2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.cascadingfamily3 {
    margin-top: 10px;
}

.statusText {
    font-size: 12px;
}

.numberText {
    font-size: 12px;
    color: #052034D6
}