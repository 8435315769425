.item_details_grid {
    display: grid;

    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    gap: 0px;
    height: 100%;

}

.item0 {

    background-color: #ffffff;
    grid-row-start: 1;
    grid-column-start: 1;

    grid-row-end: 2;
    grid-column-end: 9;

}

.item1 {

    background-color: #ffffff;
    grid-row-start: 2;
    grid-column-start: 1;

    grid-row-end: 13;
    grid-column-end: 9;

}

.h5 {
    display: table-cell;
    vertical-align: baseline;
}