.image-upload.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-upload__preview {
  width: 13rem;
  height: 13rem;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
}

.image-upload__preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.up {
  background-image: url("./up_arrow.png");
  color: #052034;
}

.file_picker_out_row {

  width: 328px;
  justify-content: space-between;
  display: flex;
  padding-right: 2%;

}

.file_picker_file_row {

  width: 70%;
  justify-content: left;
  display: flex;
  padding: 5px;
  border-bottom: solid white;
  background-color: #05203463;
  border-radius: 4px;

}

.file_picker_remove_row {
  height: 25%;
  width: 20%;
  justify-content: end;
  display: flex;
  padding-right: 2%;
  padding-top: 5px;
  ;
  text-align: center;

}


.image_box {
  height: 200px;
  width: 200px;
  justify-content: center;
  display: flex;



}

.remove_default {
  color: black;

}