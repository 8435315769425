.place {
  position: absolute;
  list-style: none;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0.5rem;
  padding-top: 20px;
  width: 408;
  max-width: 60rem;
  border-radius: 6px;
  background: white;
}


.row{
  justify-content: flex-start;
  display: flex;
  padding-top: 4%;
}