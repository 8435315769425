.h4_closed {
    background: #0D324D url("../../assest/down.png") no-repeat;
    color: white;
    background-position: 90%;
    background-size: auto;
    opacity: 1;
    margin: auto;
}

.h4_opened {
    background: #0D324D url("../../assest/up.png") no-repeat;
    color: white;
    background-position: 90%;
    background-size: auto;
    opacity: 1;
    margin: auto;
    border-bottom: 2px solid #5796C3;
}

.filter_div {
    border: 1px solid #0D324D;
    max-width: 90%;
    margin: auto;
}