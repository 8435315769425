.tasks {
    color: var(--r-13-g-50-b-77);
    text-align: left;
    font-size: 24px;
    letter-spacing: 0px;
    color: #0D324D;
    opacity: 1;
    margin-left: 44px;
}

.title {
    display: inline-flex;
    justify-content: space-between;
    width: 350px;
}

.seeall {
    font-size: 18px;
    align-self: self-end;
}

.Cards {
    display: flex;
    flex-direction: row;
    margin-left: 44px;
}

.newCard {
    width: 23%;
    height: 303px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    margin-right: 14px;
    margin-top: 15px;
}