.clmnname {
  padding-right: 5px;
}

.clmnicon {
  background: url('../../shared/assets/arrowdownlaci.png') no-repeat;
  display: inline-flex;
  width: 15px;
  /* padding-left: 10px; */
  background-position: 20%;
  background-repeat: no-repeat;
  height: 10px;
  background-size: 9px 9px;
}

.clmnfiltericon {
  background: url('../../shared/assets/tablecolumn/filter.png') no-repeat;
  display: inline-flex;
  width: 10px;
  /* padding-left: 10px; */
  background-position: 20%;
  background-repeat: no-repeat;
  height: 10px;
  background-size: 9px 9px;
}

.clmnupicon {
  background: url('../../shared/assets/tablecolumn/up-arrow.png') no-repeat;
  display: inline-flex;
  width: 10px;
  /* padding-left: 10px; */
  background-position: 20%;
  background-repeat: no-repeat;
  height: 10px;
  background-size: 9px 9px;
}

.clmndownicon {
  background: url('../../shared/assets/tablecolumn/down-arrow.png') no-repeat;
  display: inline-flex;
  width: 10px;
  /* padding-left: 10px; */
  background-position: 20%;
  background-repeat: no-repeat;
  height: 10px;
  background-size: 9px 9px;
}

.th {

  cursor: pointer;
  max-width: 150px;
  min-width: 150px;
  text-align: left;
  opacity: 1;
  font-size: 14px;
}

.Headercell {
  top: 200px;
  max-width: 150px;
  min-width: 150px;
  /* UI Properties */
  text-align: left;
  text-overflow: ellipsis;
  font: bold 12px Open Sans;
  letter-spacing: 0px;
  color: #052034;
  opacity: 1;
}

.up {
  background-image: url("../../shared/assets/up_arrow.png");
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
  max-width: 150px;
  min-width: 150px;
  /* UI Properties */
  text-align: left;

  color: aqua;
  opacity: 1;
}

.down {
  background-image: url("../../shared/assets/down_arrow.png");
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
  top: 200px;
  max-width: 150px;
  min-width: 150px;
  height: 10px;
  /* UI Properties */
  text-align: left;

  color: #265371;
  opacity: 1;
}

.default {
  background-image: url("../../shared/assets/default.png");
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
  text-overflow: ellipsis;
  flex-shrink: 0;
  top: 200px;
  max-width: 150px;
  min-width: 150px;
  /* UI Properties */
  text-align: left;

  color: #052034;
  opacity: 1;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

.up-filter {
  background-image: url("../../shared/assets/filter.png"),
    url("../../shared/assets/up_arrow.png");
  cursor: pointer;
  background-repeat: no-repeat, no-repeat;
  background-position: 85%, 100%;
  top: 200px;
  max-width: 150px;
  min-width: 150px;
  height: 10px;
  /* UI Properties */
  text-align: left;

  color: #265371;
  opacity: 1;
}

.down-filter {
  background-image: url("../../shared/assets/filter.png"),
    url("../../shared/assets/down_arrow.png");
  cursor: pointer;
  background-repeat: no-repeat, no-repeat;
  background-position: 85%, 100%;
  top: 200px;
  max-width: 150px;
  min-width: 150px;
  /* UI Properties */
  text-align: left;

  color: #265371;
  opacity: 1;
}

.default-filter {
  background-image: url("../../shared/assets/filter.png");
  cursor: pointer;
  background-repeat: no-repeat, no-repeat;
  background-position: 85%, 100%;
  top: 200px;
  max-width: 150px;
  min-width: 150px;
  /* UI Properties */
  text-align: left;

  color: #265371;
  opacity: 1;
}

.row {
  justify-content: flex-start;
  display: flex;
  padding-top: 4%;
}

.line {

  color: #052034;
  background-color: #052034;
  height: 5px;
}