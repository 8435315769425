.filesCard {
    width: 80%;
    height: 200px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    margin-left: 44px;
    margin-top: 15px;
}

@media only screen and (max-width: 550px) {
    .newCard {

        margin-top: 0px;
    }
}