* {
    box-sizing: border-box;
}

.titleBig {

    font-size: 25px;
}

.titleSmall {

    font-size: 16px;
}

.wrapper {
    overflow: auto
}

.main {
    float: left;
    width: 80%;
}

.right {
    background-color: #e5e5e5;
    float: right;
    width: 20%;
    height: 100vh;
    padding: 15px;
    margin-top: 0px;
    text-align: center;
    overflow: hidden;

}

.clmn0 {
    float: left;
    width: 100%;
    height: 5vh;
    padding-left: 44px;
    background-color: #FAAB78
}

.clmn00 {
    float: left;
    width: 100%;
    height: 15vh;

    background-color: #FFDCA9
}

.clmn1 {
    float: left;
    width: 50%;
    height: 40vh;

    background-color: #FCF9BE
}

.clmn2 {
    float: left;
    width: 25%;
    height: 40vh;

    background-color: #E8F3D6
}

.clmn3 {
    float: left;
    width: 25%;
    height: 40vh;

    background-color: #CEEDC7
}

.clmn4 {
    float: left;
    width: 30%;
    height: 40vh;

    background-color: #A8D1D1
}

.clmn5 {
    float: left;
    width: 70%;
    height: 40vh;
    overflow: hidden;
    background-color: #86C8BC
}

.clmn6 {

    width: 100%;
    height: 30vh;

    background-color: #DEBACE
}

@media only screen and (max-width: 990px) {


    .clmn1,
    .clmn2,
    .clmn3,
    .clmn4 {
        width: 100%;
        height: auto;
    }

    .clmn5 {
        width: 100%;
        height: auto;
    }

    .main {
        width: 100%;
        position: absolute;
        height: 100%;
        overflow: auto;
    }

    .right {
        display: none;
    }
}