.cell{

   
    top: 200px;
    left: 254px;
    max-width:140px;
    min-width: 140px;
    /* UI Properties */
    background: white;
    border-color: white;
    opacity: 1;
    text-align: left;
    font: normal normal 300 10px/16px Poppins;
    letter-spacing: 0px;
   
    
        
    
}