.profil {
  top: 0px;
  left: 0px;
  min-width: 192px;
  height: 100vh;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  /* UI Properties */
  background: #0d324d 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  list-style-type: none !important;
}

.logo_image {
  width: 146px;
  height: 56px;
  /* UI Properties */
  background: transparent url("../../../assest/logo_profil.png") 0% 0% no-repeat
    padding-box;
  background-position: center;
  background-size: contain;
  opacity: 1;
}

img {
  border-radius: 50%;
}

.profile_name {
  text-align: left;
  font: normal normal 300 16px/25px Open Sans;
  letter-spacing: 0px;
  color: #f8f6f9;
  opacity: 1;
}

.profile_list {
  top: 290px;
  left: 23px;
  width: 131px;
  height: 27px;
  cursor: pointer;
  /* UI Properties */
  text-align: left;
  font: normal normal 300 15px/27px Open Sans;
  letter-spacing: 0px;
  color: #f8f6f9;
  opacity: 1;
  list-style: none;
  list-style-type: none !important;
}

.profile_list::before {
  content: "";
}

.ul {
  list-style: none;
  list-style-type: none !important;
  color: #f8f6f9;
  position: relative;
}

.ul::before {
  content: "";
}

.li {
  list-style: none;
  list-style-type: none !important;
  color: #f8f6f9;
  position: relative;
}

.li::before {
  content: "";
}

.dropdown {
  position: relative;
}

.menu {
  position: absolute;

  list-style-type: none !important;
  margin: 5px 0;
  padding: 0;
  list-style: none;
  border: 1px solid grey;
  width: 150px;
}

.menu li {
  margin: 0;
  list-style: none;
  background-color: white;
  list-style-type: none !important;
}

.menu::before {
  content: "";
}

.menu li:hover {
  background-color: lightgray;
  list-style: none;
}

.menu li button {
  width: 100%;
  height: 100%;
  text-align: left;

  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

.side_drawer {
  position: fixed;
  right: 200; /*set driwer position*/
  top: 0;
  z-index: 200;
  height: 100vh;
  width: 192px;
  overflow: hidden;
  background: rgb(255, 255, 255);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
.side_drawer.open {
  transform: translateX(0%);
}
.navdot {
  list-style: none;
}
