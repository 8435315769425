.dropdown {
  top: 146px;
  left: 41px;
  width: 328px;
  height: 40px;
  border-radius: 4px;
  padding-left: 3px;
  padding-bottom: 15px;
  padding-top: 10px;
  border-color: #0D324D;

  text-align: left;
  font: normal normal normal 15px Poppins;
  letter-spacing: 0px;
  color: #0D324D;
  opacity: 1;
}


.dropdown__menu {
  list-style: inside;

  padding: 10px;
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: left;
  text-align: left;



}

.dropdown__menu_items {
  cursor: pointer;
  text-align: left;
  padding-bottom: 5px;
  font: normal normal normal 15px Poppins;
  letter-spacing: 0px;
  color: #0D324D;
  opacity: 1;


}

.dropdown__menu_search {

  top: 417px;
  left: 42px;
  height: 30px;
  z-index: 300;
  padding-bottom: 5px;
  background-color: #F8F6F9;
  border: 1px solid #0D324D;
  border-radius: 2px;
  text-align: left;
  font: normal normal normal 15px/57px Poppins;
  letter-spacing: 0px;
  opacity: 0.3;

}

.dropdown__selected2 {


  top: 146px;
  left: 41px;
  height: 40px;

  border-color: #0D324D;
  border-radius: 10px;
  cursor: pointer;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 18px Poppins;
  letter-spacing: 0px;
  color: #0D324D;
  opacity: 1;
}


.dropdown__selected {


  top: 146px;
  left: 41px;
  height: 30px;
  border-radius: 4px;
  padding: 5px;
  background: transparent url("../../../assets/drop_icon_lacivert.png") 14px 14px no-repeat padding-box;
  background-position: 96%;
  background-size: 12px;
  border: 2px solid #0D324D;
  cursor: pointer;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 12px Poppins;
  letter-spacing: 0px;
  color: #0D324D;
  opacity: 1;
  max-width: 320px;
}

.label {
  padding-bottom: 10px;
  text-align: left;
  font: bold 12px/16px Poppins;
  letter-spacing: 0.4px;
  color: #0D324D;
  opacity: 1;
}