.file_picker_file_row {
  width: 300px;
  justify-content: left;
  display: flex;
  padding: 5px;
  border-bottom: solid white;
  background-color: #05203463;
  border-radius: 4px;
  flex-direction: 'row';
  overflow: hidden;
  text-overflow: ellipsis;
  max-lines: 1;
  list-style: none;

}

.closeicon {
  background: url('../../shared/assets/close-blue.png') no-repeat;
  float: right;
  height: 15px;
  width: 15px;
  margin-top: 5px;
  margin-right: 15px;
  background-size: contain;

}