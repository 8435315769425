.sidebar__main {
  top: 0px;
  left: 0px;
  min-width: 192px;
  height: 100vh;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  /* UI Properties */
  background: #0d324d 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  list-style-type: none !important;
}

.sidebar__logo {
  width: 176px;
  height: 56px;
  margin: 20px;
  /* UI Properties */
  background: transparent 0% 0% no-repeat padding-box;
  background-position: center;
  background-size: contain;
  opacity: 1;
  display: relative;
  object-fit: contain;
}

.sidebar__avatar {
  width: 80px;
  height: 80px;
  /* UI Properties */
  background: transparent 0% 0% no-repeat padding-box;
  background-position: center;
  background-size: contain;
  opacity: 1;
}

.sidebar__name {
  text-align: left;
  font: normal normal 300 16px/25px Open Sans;
  letter-spacing: 0px;
  color: #f8f6f9;
  opacity: 1;
}
