.form-control {
  top: 146px;
  left: 41px;
  width: 328px;
  border-radius: 10px;
  padding-bottom: 5px;
  padding-top: 25px;
  opacity: 1;
  text-align: left;
  font: bold 12px/16px Poppins;
  letter-spacing: 0.4px;
  color: #0D324D;
  opacity: 1;
  /*display: none;*/
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {

  text-align: left;
  font: bold 12px/16px Poppins;
  letter-spacing: 0.4px;
  color: #0D324D;
  opacity: 1;
}

.form-control input,
.form-control textarea {
  top: 146px;
  left: 41px;
  width: 320px;
  height: 30px;
  border-color: #0D324D;
  border-radius: 4px;
  border-width: 2px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 12px Poppins;
  letter-spacing: 0px;
  color: #0D324D;
  opacity: 1;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: white;

}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}