.input {
    top: 146px;
    left: 41px;
    max-width: 320px;
    height: 30px;
    border-color: #0D324D;
    border-radius: 4px;
    border-width: 2px;
    /* UI Properties */
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    letter-spacing: 0px;
    color: #0D324D;
    opacity: 1;
}

.label {
    color: #0d324d;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .4px;
    opacity: 1;
    text-align: left;
}

.div {
    padding-bottom: 5px;
    padding-top: 25px;
    display: grid;
}