.menu-links {
  list-style: none;
  margin: 0;
  padding: 5px;
  max-width: 100px;
  min-width: 100px;
  padding-left: 10px;
  background: #f8f6f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  font-size: 11px;
  background-color: white;
  text-align: left;
}

.menu-links li {
  padding-left: 30px;
  text-align: left;
  text-decoration-line: underline grey;
  font: normal normal 300 16px Poppins;
  letter-spacing: 0px;
  color: #052034;
  opacity: 1;
}

.menu-links span {
  color: #c52b2b;
  text-decoration: none;
  cursor: pointer;
}

.menu-links a {
  background-color: gray;
  border-bottom: 1px solid #0d324d;
  color: #292929;
  text-decoration: none;
  padding: 0;
  margin: 0 0;
}

.menu_line {
  border-bottom: 1px solid #b3b3b3b6;
  padding: 3px;
}
