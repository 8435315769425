.side-drawer {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  height: 100%;
  width: 390px;
  padding-top: 1%;
  padding-left: 2%;
  background: rgb(255, 255, 255);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  overflow-y: scroll;
  padding-bottom: 20px;
}

@media only screen and (max-width: 390px) {

  .side-drawer {

    width: 320px;

  }
}