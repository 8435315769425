.input {
    height: 25px;
    margin-right: 7px;
}

.button_007 {
    height: 29px;
    background-color: #5796C3;
    border: none;
    color: white;
}

.div1 {}

.div2 {
    display: flex;
    position: absolute;
    top: 90%;
    left: 50%;
    margin: -50px 0 0 -50px;
}

.button {
    top: 136px;
    left: 215px;
    width: auto;
    height: 35px;
    text-align: left;
    font: normal normal normal 19px/26px Open Sans;
    letter-spacing: 0px;
    color: #f8f6f9;
    opacity: 1;
    background: #0d324d;
    color: white;
    cursor: pointer;
    margin-right: 1rem;
    text-decoration: none;
    display: inline-block;
}

.button:focus {
    outline: white;
}

.button:hover,
.button:active {
    background: #216291;
    border-color: white;
}

.button--2:hover,
.button--2:active {
    background: white;
    border-color: white;
}

.button--inverse {
    background: transparent;
    color: #ff0055;
}

.button--2inverse {
    background: white;
    color: white;
}

.button--inverse:hover,
.button--inverse:active {
    color: white;
    background: #ff0055;
}

.button--danger {
    background: #830000;
    border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
    background: #f34343;
    border-color: #f34343;
}

.button--small {
    font-size: 0.8rem;
}

.button--big {
    font-size: 1.5rem;
}

.button--2 {
    top: 136px;
    left: 215px;
    width: 180px;
    height: 35px;
    text-rendering: auto;
    color: buttontext;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: pointer;
    background-color: white;
    box-sizing: border-box;
    margin: 0em;
    font: 400 11px system-ui;
    padding: 1px 7px 2px;
    border-width: 1px;
    border-style: solid;
    border-color: white;
    border-image: initial;

    text-align: left;
    font: normal normal normal 16px Open Sans;
    letter-spacing: 0px;
    color: #0d324d;
    opacity: 1;
}

.button--3 {
    display: block;
    top: 136px;
    left: 215px;
    text-align: center;
    border-radius: 5px;
    font: normal normal normal 15px/26px Open Sans;
    letter-spacing: 0px;
    color: #f8f6f9;
    opacity: 1;
    background: #0d404d;
    color: white;
    cursor: pointer;
    margin-right: 1rem;
    text-decoration: none;
    display: inline-block;
}

.button--4 {
    display: flex;
    width: 100px;

    height: 30px;
    top: 136px;
    left: 215px;
    text-align: center;
    font: normal normal normal 15px/26px Open Sans;
    letter-spacing: 0px;
    color: #f8f6f9;
    opacity: 1;
    background: #0d404d46;
    border-color: white;
    color: white;
    cursor: pointer;
    margin-right: 1rem;
    text-decoration: none;
    display: inline-block;
}

.button--5 {
    top: 136px;
    left: 215px;
    width: 100px;
    height: 35px;
    text-align: left;
    font: normal normal normal 15px/26px Open Sans;
    letter-spacing: 0px;
    color: #f8f6f9;
    opacity: 1;
    background: #0d324d;
    text-align: center;
    color: white;
    cursor: pointer;
    margin-right: 1rem;
    text-decoration: none;
    display: inline-block;
}

.button--6 {
    top: 136px;
    left: 215px;
    width: 100px;
    height: 20px;
    text-rendering: auto;
    color: buttontext;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: pointer;
    background-color: white;
    box-sizing: border-box;
    margin: 0em;
    font: 400 11px system-ui;
    padding: 1px 7px 2px;
    border-width: 1px;
    border-style: solid;
    border-color: white;
    border-image: initial;

    text-align: left;
    font: bold 10px Open Sans;
    letter-spacing: 0px;
    color: #0d324d;
    opacity: 1;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
    background: #ccc;
    color: #979797;
    border-color: #ccc;
    cursor: not-allowed;
}