.card {
  top: 0px;
  left: 192px;
  width: 100%;
  height: 50px;
  /* UI Properties */
  background: #F8F6F9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding-left: 30px;
  overflow: hidden;
  padding-top: 30px;
  text-align: left;
  font: normal normal normal 26px/36px Open Sans;
  letter-spacing: 0px;
  color: #0D324D;
  opacity: 1;

}

.row {
  width: 90%;
  justify-content: space-between;
  display: flex;
  overflow: hidden;
  padding-right: 2%;
}


.search {
  top: 146px;
  left: 41px;
  width: 200px;
  height: 30px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #0D324D;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 15px Poppins;
  background: url("../assest/search_icon.png")no-repeat scroll 7px 7px;
  padding-left: 30px;
  letter-spacing: 0px;
  color: #0D324D;
  opacity: 1;

}


@media screen and (max-width:750px) {
  .card {
    top: 0px;
    left: 192px;
    width: 100%;
    height: 50px;
    overflow: hidden;
    /* UI Properties */
    background: #F8F6F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding-left: 2%;
    padding-right: 11%;
    padding-top: 2%;
    text-align: left;
    font: normal normal normal 20px/36px Open Sans;
    letter-spacing: 0px;
    color: #0D324D;
    opacity: 1;

  }



  .row {
    width: 90%;
    overflow: hidden;
    justify-content: space-between;
    display: flex;




  }


  .search {
    top: 146px;
    left: 41px;
    width: 100px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid white;
    overflow: hidden;
    /* UI Properties */
    text-align: left;
    font: normal normal normal 15px Poppins;
    background: url("../assest/search_icon.png")no-repeat scroll 4px 4px;
    padding-left: 30px;
    letter-spacing: 0px;
    color: #0D324D;
    opacity: 1;

  }
}

.table_body {

  display: block;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  min-width: 500px;
  height: 100vh;
  background-color: white;




}





.body_row {
  width: 100%;
  height: calc(100%-230px);
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  background: white 0% 0% no-repeat padding-box;


}

.profil {

  top: 0px;
  left: 0px;
  width: 192px;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  /* UI Properties */
  background: #0D324D 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;

}

.row_for_hamburger {
  max-height: 50px;
  overflow: hidden;
  display: flex;
  mask-repeat: none;
  flex-direction: row;




}


.hamburger {

  width: 24px;
  height: 35px;
  padding-right: 15px;
  background: url("../assest/hamburger_manu_icon1.png") 0% 0% no-repeat padding-box;
  background-position: center;

}