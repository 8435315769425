.newtablestyle {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.newtablestyle td,
.newtablestyle th {
  border: 1px solid #ddd;
  padding: 8px;
}

.newtablestyle tr:nth-child(even) {
  background-color: #f2f2f2;
}

.newtablestyle tr:hover {
  background-color: #ddd;
}

.newtablestyle th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0D324D;
  color: white;
  border-bottom: 2px solid #5796C3;

}


.card {
  display: block;
  position: absolute;
  top: 230px;
  align-self: flex-end;
  width: calc(90%-40px);
  height: calc(100vh-230px);
  align-items: left;
  overflow-x: auto;
  overflow-y: hidden;

  /* UI Properties */
  background: white 0% 0% no-repeat padding-box;
  opacity: 1;
}

.scroll_container {
  display: block;
  min-width: 100%;
  height: 300;
  width: 1000px;
  padding-left: 20px;
  height: calc(100vh-280px);
  overflow-x: hidden;
  padding-bottom: 20px;
  scrollbar-gutter: stable;
  overflow-wrap: normal;
  background-color: white;
}

.scroll_container2 {
  display: block;
  min-width: 100%;
  width: 100%;
  padding-left: 20px;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow-wrap: normal;
  background-color: white;
}

a {
  color: #0000ff;
  text-decoration: none;
}

a::before {
  content: '.';
  color: white;
}

.local-link::before {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}