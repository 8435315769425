.wrapper {
    display: grid;
    /* grid-template-rows: 1fr 1fr 1fr; */
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0px;
    width: 100vw;
    height: 100vh;

}

.box0 {

    background-color: #0D324D;
    color: white;
    border-bottom: 2px solid #5796C3;
    grid-row-start: 1;
    grid-column-start: 1;

    grid-row-end: 2;
    grid-column-end: 13;

    display: flex;
    align-items: center;

    padding: 15px;
    position: relative;
}

.box1 {

    background-color: #ffffff;
    grid-row-start: 2;
    grid-column-start: 1;

    grid-row-end: 13;
    grid-column-end: 3;

    overflow-y: scroll
}

.box2 {

    background-color: #ffffff;
    grid-row-start: 2;
    grid-column-start: 3;

    grid-row-end: 9;
    grid-column-end: 13;

    overflow-y: scroll;

    padding: 1%;
}

.box3 {

    background-color: #ffffff;
    grid-row-start: 9;
    grid-column-start: 3;

    grid-row-end: 13;
    grid-column-end: 8;
    overflow-y: scroll;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
    padding: 1%;
    contain: size;
}

.box4 {

    background-color: #ffffff;
    grid-row-start: 9;
    grid-column-start: 8;

    grid-row-end: 13;
    grid-column-end: 13;
    overflow-y: scroll;
    border-top: 1px solid #000000;
    padding: 1%;
}