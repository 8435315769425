.equipmenttable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.equipmenttable td {
    border: 1px solid #ddd;
    padding: 8px;

}

.equipmenttable th {
    border: 1px solid #ddd;
    padding: 8px;

}

.equipmenttable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.equipmenttable tr:hover {
    background-color: #ddd;
}

.equipmenttable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #0D324D;
    color: white;
    border-bottom: 2px solid #5796C3;
}

.solid_divider {
    border-top: 1px solid #bbb;
}