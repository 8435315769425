.filter_criteria{
    display: flex;
    width: calc(100%-30px) ;
    
    height: 40px;
    top: 136px;
    left: 215px;
    justify-content:space-between;
    background: var(--unnamed-color-f8f6f9) 0% 0% no-repeat padding-box;
    background: #F8F6F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding-left: 10px;
    padding-right: 50px;
    font: var(--unnamed-font-style-normal) normal normal 22px/36px var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-0d324d);
    font: normal normal normal 22px/36px Open Sans;
    text-align: left;
    letter-spacing: 0px;
    color: #0D324D;
    opacity: 1;
  
  }

  .search{ top: 146px;
    left: 41px;
    width: 275px;
    height: 30px;
    border-radius:4px;
    border: 1px solid #0D324D;
    /* UI Properties */
    text-align: left;
    font: normal normal normal 15px Poppins;
    background: url("../../assest/search_icon.png")no-repeat scroll 7px 7px;
     padding-left:30px;
    letter-spacing: 0px;
    color: #0D324D;
    opacity: 1;
    
     }


     .row{
      width: 300px;
        
        justify-content:left;
        display: flex;
        
    }

    .file_picker_out_row{

        width: 328px;
        justify-content:space-between;
        display: flex;
        padding-right: 2%;
        
      }
      .file_picker_file_row{
      
        width: 300px;
        justify-content:left;
        display: flex;
        padding: 5px;
        border-bottom: solid white;
        background-color: #05203463;
        border-radius: 4px;
        flex-direction: 'row';
        max-lines: 1;

      
      }


      .filter_criteria_name{
      
        font: var(--unnamed-font-style-normal) normal normal 22px/36px var(--unnamed-font-family-italic-sans);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-0d324d);
        font: normal normal normal 22px/36px italic Sans;
        text-align: left;
        letter-spacing: 0px;
        color: #0d324d6a;
        opacity: 1;
      
      }    