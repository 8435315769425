.login-page {
  width: 100%;
  
  flex-direction: row;
  min-height: 100vh;
  min-width: 500px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  gap:-50px;
  display: flex;
  justify-content: center;
  background-color:#0D324D ;
  align-items: center;
  margin: 0 auto;
}

.form {
  position: relative;
  z-index: 1;
  background: #0D324D;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 10px;
  text-align: center;
  
}

.form input {
  top: 607px;
  left: 732px;
  width: 457px;
  height: 50px;
  /* UI Properties */
  border: 1px solid #ECE5F0A6;
  border-radius: 4px;
  opacity: 1;
  outline: 0;
  background: #0D324D;
  width: 100%;
  border: solid #ECE5F0A6;
  padding: 10px;
  margin: 0 0 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form button {
  top: 851px;
left: 732px;
width: 457px;
height: 50px;
/* UI Properties */

border-radius: 4px;
opacity: 1;
  text-transform: uppercase;
  outline: 0;
  background: var(--r-236-g-229-b-240) 0% 0% no-repeat padding-box;
  background: #ECE5F0 0% 0% no-repeat padding-box;background: var(--r-236-g-229-b-240) 0% 0% no-repeat padding-box;
  background: #ECE5F0 0% 0% no-repeat padding-box;
  width: 100%;
  border: 0;
  padding: 10px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-22)/var(--unnamed-line-spacing-33) var(--unnamed-font-family-poppins);
  letter-spacing: var(--unnamed-character-spacing-0-21);
  color: var(--r-236-g-229-b-240);
  text-align: center;
  font: normal normal normal 22px/33px Poppins;
  letter-spacing: 0.21px;
  color: #0D324D;
  opacity: 1;
}

.form button:hover,
.form button:active,
.form button:focus {
  background: #89cff0;
}

.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}

.form .message a {
  color: #89cff0;
  text-decoration: none;
}

.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}

.container:before,
.container:after {
  content: "";
  display: block;
  clear: both;
}

.container .info {
  margin: 50px auto;
  text-align: center;
}

.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}

.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}

.container .info span a {
  color: #000000;
  text-decoration: none;
}

.container .info span .fa {
  color: #ef3b3a;
}

body {
  background: #ffffff;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(right, #ffffff, #ffffff);
  background: -moz-linear-gradient(right, #ffffff, #ffffff);
  background: -o-linear-gradient(right, #ffffff, #ffffff);
  background: linear-gradient(to left, #ffffff, #ffffff);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo_image{
  top: 125px;
  left: 670px;
  width:550px;
  height: 350px;
  padding-left: 200px;
  /* UI Properties */
  background: transparent url("../../assest/logo.png") 0% 0% no-repeat padding-box;
  background-position: center;
  background-size: contain;
  opacity: 1;
}

.border_radius{
 width: 80px;
 height: 80px;
 border-radius: 15px;
 border-color: white;

}


