.card {
  top: 0px;
  left: 192px;
  width: 1728px;
  height: 60px;
  overflow: hidden;
  /* UI Properties */
  background: #F8F6F9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding-left: 2%;
  padding-top: 2%;
  text-align: left;
  font: normal normal normal 26px/36px Open Sans;
  letter-spacing: 0px;
  color: #0D324D;
  opacity: 1;
}



.baslik {
  top: 24px;
  left: 224px;
  overflow: hidden;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 26px/36px Open Sans;
  letter-spacing: 0px;
  color: #0D324D;
  opacity: 1;
}



.row {
  justify-content: flex-start;
  display: flex;
  padding-top: 50px;
  overflow: hidden;
}

.card2 {
  top: 0px;
  left: 192px;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: 30px;
  /* UI Properties */
  background: white 0% 0% no-repeat padding-box;
  opacity: 1;
}


.edit {
  top: 141px;
  left: 342px;
  width: 22px;
  height: 22px;
  /* UI Properties */
  background: transparent url("../../assest/Edit-icon.png") 0% 0% no-repeat padding-box;
  opacity: 1;
}

.cop {
  top: 141px;
  left: 342px;
  width: 22px;
  height: 22px;
  /* UI Properties */
  background: transparent url("../../assest/delete-icon.png") 0% 0% no-repeat padding-box;
  opacity: 1;
}

.recycle {
  top: 141px;
  left: 342px;
  width: 22px;
  height: 22px;
  /* UI Properties */
  background: transparent url("../../assest/recycle-bin.png") 0% 0% no-repeat padding-box;
  opacity: 1;
}

.layoff {
  top: 141px;
  left: 342px;
  width: 22px;
  height: 22px;
  /* UI Properties */
  background: transparent url("../../assest/layoff.png") 0% 0% no-repeat padding-box;
  opacity: 1;
}

.exel {
  top: 141px;
  left: 342px;
  width: 22px;
  height: 22px;
  /* UI Properties */
  background: transparent url("../../assest/exel-icon.png") 0% 0% no-repeat padding-box;
  opacity: 1;
}

.savei {
  top: 141px;
  left: 342px;
  width: 22px;
  height: 22px;
  /* UI Properties */
  background: transparent url("../../assest/savei.png") 0% 0% no-repeat padding-box;
  opacity: 1;
}


.candy_box {
  top: 141px;
  left: 342px;
  width: 22px;
  height: 22px;
  /* UI Properties */
  background: transparent url("../../assest/candy.png") 0% 0% no-repeat padding-box;
  opacity: 1;
}


.rowbuton {
  justify-content: center;
  display: flex;

}

.row_ikon_buton_bosluk {
  justify-content: flex-start;
  display: flex;
  padding-left: 10px;
}

@media screen and (max-width:750px) {

  .row {
    justify-content: flex-start;
    display: flex;
    padding-top: 6%;
  }

}

.menu_links {
  list-style: none;
  margin: 0;
  padding: 5px;
  width: 100px;
  padding-left: 10px;
  background: #F8F6F9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  font-size: 8px;
  background-color: white;
  text-align: left;
}

.menu-links li {

  padding-left: 30px;
  text-align: left;
  text-decoration-line: underline grey;
  font: normal normal 300 8px Poppins;
  letter-spacing: 0px;
  font-size: 8px;
  color: #052034;
  opacity: 1;
}

.menu-links span {
  color: #c52b2b;
  text-decoration: none;
  cursor: pointer;
}

.menu-links a {
  background-color: gray;
  border-bottom: 1px solid #0D324D;
  color: #292929;
  text-decoration: none;
  font-size: 8px;
  padding: 0;
  margin: 0 0;
}

.menu_line {

  border-bottom: 1px solid #b3b3b3b6;
  padding: 3px;

}