.place {
  position: absolute;
  list-style: none;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0.5rem;
  padding-top: 20px;
  padding-bottom: 50px;
  width: 408;
  max-width: 60rem;
  border-radius: 6px;
  background: white;
  width: -webkit-fill-available;
}


.row {
  justify-content: flex-start;
  display: flex;
  padding-top: 4%;
}

.row2 {
  justify-content: flex-start;
  display: flex;
  padding-top: 150px;
}

.closeicon {
  background: url('../../shared/assets/close-blue.png') no-repeat;
  float: right;
  height: 15px;
  width: 15px;
  margin-top: 5px;
  margin-right: 15px;
  background-size: contain;
  margin-left: auto;
}

nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background: white;
}