.body{
    width:calc(90%-40px) ;
    height: 100vh;
    display: flex;
   
    justify-content:center;
    overflow: hidden;
    flex-direction: column;
    background-color: red;
   
   
    
  }
  .body_out{
    width:calc(90%-30px) ;
    display: flex;
    justify-content:center;
    flex-direction: column;
    background-color: white;
   
   
  }
  .body_in{
    top: 100px;
    width:100%;
    height: 100vh;
    display: flex;
    justify-content:center;
    background-color: white;
    list-style: none;
    overflow-y: scroll;
    align-items: flex-start;
    padding-top: 200px;
    gap:60px;
    flex-wrap: wrap;
   
   
    
  }
  

  .card{
   
    top: 0px;
    width: 100%;
    height: 50px;
    overflow: hidden;
    /* UI Properties */
    background: #F8F6F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding-left: 2%;
    padding-top: 2%;
    text-align: left;
    font: normal normal normal 26px/36px Open Sans;
    letter-spacing: 0px;
    color: #0D324D;
    opacity: 1;
  
    }

    .choose_page{
        top: 232px;
        left: 591px;
        width: 281px;
        height: 148px;
        /* UI Properties */
        box-shadow: 0px 3px 6px #00000029;
        opacity: 1;
        padding-left: 2%;
        padding-right: 2%;
        
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-open-sans);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-0c1c27a6);
       
        font: normal normal 600 18px/24px Open Sans;
        letter-spacing: 0px;
        color: #0C1C27A6;
        text-align: center;
        opacity: 1;

    }

    .space{
      justify-content: flex-start;
      display: flex;
      padding-top:50px;
      padding-left: 50px;
    }

    .user_image{
      top: 270px;
      left: 686px;
      width: 186px;
      height: 109px;
      padding-left: 110px;
      /* UI Properties */
      background: transparent url("../assest/user.png") 0% 0% no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }

    .suppliers_image{
      top: 270px;
      left: 686px;
      width: 186px;
      height: 109px;
      padding-left: 110px;
      /* UI Properties */
      background: transparent url("../assest/supp.png") 0% 0% no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }

    .departman_image{
      top: 270px;
      left: 686px;
      width: 186px;
      height: 109px;
      padding-left: 110px;
      /* UI Properties */
      background: transparent url("../assest/departman.png") 0% 0% no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }
    .list_image{
      top: 270px;
      left: 686px;
      width: 186px;
      height: 109px;
      padding-left: 110px;
      /* UI Properties */
      background: transparent url("../assest/list.png") 0% 0% no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }

    .body_row{
      width: 100%;
      height: calc(100%-230px) ;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      background: white 0% 0% no-repeat padding-box;
  
  
    }

    
  .profil{
    
    top: 0px;
    left: 0px;
    width: 192px;
    height:100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    /* UI Properties */
    background: #0D324D 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;

  }