.cell {

	top: 200px;
	left: 255px;
	height: 40px;
	max-width: 150px;
	min-width: 150px;
	overflow: hidden;
	border-bottom: 0.5px solid #EDEBE9;
	opacity: 1;
	text-align: left;
	text-overflow: ellipsis;
	flex-shrink: 1;
	letter-spacing: 0px;
	color: #052034;
	font-size: 14px;
	height: 60px;
	padding-top: 20px;

}

.checkboxcell {
	border-bottom: 0.5px solid #edebe9;
}

.cell_row {
	top: 200px;
	left: 255px;
	width: 200px;
	height: 30px;
	padding-left: 1%;
	background: white;
	border-bottom: 0.5px solid #05203409;
	opacity: 1;
	text-align: left;
	text-overflow: ellipsis;
	letter-spacing: 0px;
	color: #052034;
}

.border {
	top: 458px;
	left: 262px;
	width: 1658px;
	height: 0px;
	/* UI Properties */
	border: 0.5px solid #052034;
	opacity: 1;
}

.trTrue {
	background-color: #F3F2F1;
}

.trTrue:hover {
	background-color: #F3F2F1;
}

.trFalse {
	background-color: #FFFFFF;
}

.trFalse:hover {
	background-color: #F3F2F1;
}