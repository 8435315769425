.body{    
  display: block;
  width:calc(100%) ;
  min-width: 500px;
  height: 100vh ;
  background-color:white;
  list-style: none;
  overflow: hidden;

}
 

.body_productarea{ 
top: 200px; 
right: 200px;
left: 200px;  
display: block;
width:calc(100%);
min-width: 500px;
height: 100vh ;
overflow-y: auto;
list-style: none;


}

.body_row{
  width: calc(100%);
  height: 100vh ;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  background: white 0% 0% no-repeat padding-box;
  list-style: none;
 
}

.profil{
  top: 0px;
  left: 0px;
  min-width: 192px;
  height:100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  /* UI Properties */
  background: #0D324D 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  list-style: none;
}

.card{
 
  top: 0px;
  width: 100%;
  height: 50px;
  overflow: hidden;
  /* UI Properties */
  background: #F8F6F9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding-left: 2%;
  padding-top: 2%;
  text-align: left;
  font: normal normal normal 26px/36px Open Sans;
  letter-spacing: 0px;
  color: #0D324D;
  opacity: 1;
  list-style: none;
  
 

  }

  .project_area{
  display: flex;
  width: calc(100%) ;
  min-width: 500px;
  height: 100vh ;
  padding-left: 25px;
  background-color:white;
  padding-top: 100px;
  list-style: none;
  overflow-y: auto;
  overflow-x: auto;
  flex-direction: column;

  }

  .project_card{
 
  width: 230px;
  height: 120px;
  padding: 15px;
  /* UI Properties */
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  list-style: none;
  text-align: left;
  font: normal normal 300 24px/33px Open Sans;
  letter-spacing: 0px;
  color: #0d324db4;
  opacity: 1;
  list-style: none;
  
  }

  .header_text{
  top: 448px;
  left: 267px;
  width: 90px;
  height: 33px;
  /* UI Properties */
 
  text-align: left;
  font: normal normal 300 24px/33px Open Sans;
  letter-spacing: 0px;
  color: #0d324db4;
  opacity: 1;
  list-style: none;
  }

  .project_header{

  color: var(--r-13-g-50-b-77);
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #0D324D;
  opacity: 1;
  list-style: none;
  }